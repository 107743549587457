.negocioForm-layout {
    @media screen and (max-width: 489px) {
        .p-formgroup-inline {
            .p-field {
                margin-bottom: 1em !important;
            }
        }
    }

    textarea {
        resize: none;
    }
}